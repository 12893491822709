import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/diarree-bij-kinderen/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/diarrhee-chez-les-enfants/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="3aacd39d1c7f18c4e15622bcb9c42972660a0c42"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li className="active" id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/fr/materiel-dinformation-diarrhee/">
                          Matériel
                          <br />
                          d'information
                        </a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/fr/diarrhee-chez-les-enfants/">
                  {" "}
                  Diarrhée chez les enfants
                </a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Conseils en cas de diarrhée</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Conseils en cas de diarrhée"
                  />
                </div>
                <p>
                  Quand il s'agit du traitement des symptômes de la diarrhée, il
                  existe encore de nombreuses incertitudes. Lire quelques
                  conseils.
                </p>
                <a href="/fr/la-diarrhee/conseils-pour-le-traitement-des-symptomes-de-la-diarrhee/">
                  Conseils diarrhée
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
				<a href="/fr/medicaments-imodium/">
                <h4>Quel IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/Range_packshot_NL_small.JPG"
                    width="170"
                    height="102"
                    alt="Quel IMODIUM®?"
                  />
                </div>
                <p>
                  Cherchez quel <strong>IMODIUM®</strong> est le plus adapté
                  pour vous.&nbsp;
                </p>
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Comment agit IMODIUM®?</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/widget.jpeg"
                    width="170"
                    height="102"
                    alt="Comment agit IMODIUM®?"
                  />
                </div>
                <p>
                  Cette vidéo montre comment Imodium<sup>®</sup>&nbsp;aide à
                  restaurer l‘équilibre naturel de vos intestins.
                </p>
                <a href="/fr/comment-agit-imodium/">Plus d'info</a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-9">
            <h1>Diarrhée chez les enfants</h1>
            <div className="img">
              <img
                src="/assets/files/pages/kinder.jpeg"
                width="701"
                height="289"
                alt="Diarrhée chez les enfants"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Ce qui cause de la diarrhée chez les enfants</h2>
              </div>
              <div className="nine columns">
                <p>
                  Durant les trois premières années, un{" "}
                  <strong>enfant souffre de diarrhée</strong> en moyenne une à
                  deux fois par an. La cause principale se compose des maladies
                  virales, qui sont responsables d’environ 70 % de tous les cas
                  de diarrhée. Il est actuellement possible de faire vacciner
                  son enfant contre les rotavirus, particulièrement fréquents.
                  Mais une intolérance alimentaire ou une allergie peut
                  également provoquer de la diarrhée.&nbsp;Si une diarrhée
                  survient régulièrement, il faut consulter un médecin.
                </p>
              </div>
            </div>
            <p>
              Quelle que soit la cause, la diarrhée de l'enfant doit être prise
              en charge rapidement. Certainement chez les bébés et les jeunes
              enfants. Quand un enfant a la diarrhée, cela peut mener, plus vite
              que chez l'adulte, à une déshydratation. Celle-ci s'accompagne le
              plus souvent d'une perte d'électrolytes indispensables.
            </p>
            <h4>
              Comment traiter les symptômes de la diarrhée chez les enfants à
              partir de 6 ans ?
            </h4>
            <p>
              Quand des enfants ont la diarrhée, il est en premier lieu
              important de veiller à ce qu’ils absorbent suffisamment de
              liquide.&nbsp;Pour traiter la diarrhée des enfants à partir de 6
              ans, on peut obtenir en pharmacie sans prescription{" "}
              <a href="/fr/medicaments-imodium/">IMODIUM®</a>. IMODIUM® réduit l'activité intestinale excessive, de sorte que les selles redeviennent plus fermes et que la diarrhée diminue. Il réduit la
              durée de la diarrhée et prévient donc la perte d'eau et
              d'électrolytes. En outre, des solutions d’électrolytes prêtes à
              l’emploi spécialement destinées aux enfants sont également
              disponibles en pharmacie, afin de renouveler la perte en minéraux.
            </p>
            <h4>
              Comment traiter les symptômes de la diarrhée chez les enfants de
              moins de 6 ans ?
            </h4>
            <p>
              Pour&nbsp;les enfants entre 2 et 6 ans, veuillez
              consulter&nbsp;votre médecin.
            </p>
            <p>
              Les compléments d’eau et d’électrolytes à base de solutions
              d’électrolytes de la pharmacie sont administrés de préférence
              entre les repas ‘normaux’. Votre pharmacien vous indiquera comment
              préparer et administrer ces mélanges. Une fois que la solution est
              prête, il faut la conserver au frigo, et la consommer tout au long
              de la journée. En l'agrémentant d'un goût qu'il apprécie (par
              exemple un peu de cola), votre enfant la boira plus facilement.
            </p>
            <h4>Quand dois-je consulter un médecin ?</h4>
            <p>
              Observez attentivement votre enfant. Si le moindre changement se
              produit dans son comportement, consultez immédiatement un
              médecin.&nbsp;
            </p>
            <p>Les symptômes suivants peuvent indiquer une déshydratation:</p>
            <ul>
              <li>
                une urine moins abondante et fortement colorée (contrôlez les
                langes des bébés)
              </li>
              <li>des pleurs sans larmes</li>
              <li>
                une peau assechée qui ne reprend pas automatiquement sa forme
                lorsqu'on la pince.
              </li>
              <li>des yeux enfonçés</li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
